var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About me")])]
    ),
    _vm.userData
      ? _c("div", { staticClass: "user-profile" }, [
          _c(
            "div",
            { staticClass: "box-center" },
            [
              _c(
                "pan-thumb",
                {
                  attrs: {
                    image: _vm.userData.avatar,
                    height: "100px",
                    width: "100px",
                    hoverable: false
                  }
                },
                [
                  _c("div", [_vm._v("Hello")]),
                  _vm._v(" " + _vm._s(_vm.userData.adminsRole.name) + " ")
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "box-center" }, [
            _c("div", { staticClass: "user-name text-center" }, [
              _vm._v(" " + _vm._s(_vm.userData.fullName) + " ")
            ]),
            _c("div", { staticClass: "user-role text-center text-muted" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("uppercaseFirstChar")(_vm.userData.adminsRole.name)
                  ) +
                  " "
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }